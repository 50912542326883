.show-more {
    color: var(--color2);
    font-size: 0.75rem;
    padding: 0.4rem 0.5rem;
    text-align: right;

    .show,
    .hide {
        cursor: pointer;

        .badge {
            font-size: 0.8rem;
            background-color: var(--color6);
            color: var(--color1);
        }
    }

    .ellipsis{
        
        padding: 0 0.2rem;
        vertical-align: text-bottom;
        line-height: 0.3rem;
        color: var(--color2);
        svg{
            font-size: 1.5rem;
            margin: 0 0.2rem;
            margin-bottom: -6px;
        }
        
    } 
    &:hover{
        .ellipsis{
            color: var(--color3);
        }
    }
}
