@import "./../../../commons/scss/_common-mixins.scss";


.dark .inhovate-custom-toggle .item:not(.item-no-selected) {
    @include form-selected-element-dark;
    & > svg:hover {
        color:var(--color1)
    }
}

.dropdown.outlined .common-dropdown-toggle {
    position: inherit;
}
.dropdown .common-dropdown-toggle .item {
    width: max-content;
    margin: 1px;
    line-height: 16px;
    .error{
        font-style: italic;
        color: var(--color3lighten);
    }
}

.dropdown .common-dropdown-toggle .item.item-max-size {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 16px;
    width: 100%;
    margin: 0;
}

.no-toggle .inhovate-custom-toggle { 
    width: 0px;
    padding-right: unset ;
    
    .common-dropdown-toggle {
        display:none;   
    }
    .custom-toggle-icon {
        right: unset;
        display: contents;
    }
}

.inhovate-custom-toggle{
    cursor:pointer;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    padding-right: 1.7rem;

}

.dropdown.dropdown-selector:not(
    .custom-node-section-designer-button-dropdown,
    .datasets-button-dropdown-selector,
    .screen-def-button-dropdown,
    .parameters-and-options) .inhovate-custom-toggle,
.inhovate-custom-toggle .item, .item-inhovate-custom-toggle{


    @include form-selected-element-toggle;
    margin: 0;
    padding: 0.2rem 0.5rem;
    width: 100%;
    display: inline-flex;
    flex-flow: row;
    flex-wrap: wrap;
    position: relative;
    max-width: unset;
    min-height: 20px;
    & > svg {
        margin: 3px 5px;
    }

    > .custom-toggle-icon{
        height: 18px;
    }
  
}

// READ ONLY
.dropdown-selector.read-only.dropdown{   
    .inhovate-custom-toggle,
    .inhovate-custom-toggle .item {
        color: var(--color4);
        cursor: not-allowed;
        background-color: var(--color2-ultralight);
    }
}

.simple-item-list,
.list-in-grid {
    .common-dropdown-collapse-menu{
    
        position: absolute;
        top: -18px;
        right: 2px;
        width: 23px;
        height: 23px;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;
        background-color:  var(--color6);
        color: var(--color6lighten);
        padding: 5px 5px 0 7px;
        @include border-radius(100%);
        &:hover{
            color: var(--color1);
        }
        border: solid 1px #ffffff7a;
        svg{
            margin-left: -1.25px;
            margin-top: -1.85px;
            display: block;
        }
    }
    
}
.simple-item-list{
    background-color:  var(--color1);
}

.common-dropdown-menu.dropdown-menu {
    background-color: transparent;
    border: none;
}

.dropdown .custom-toggle-icon.standard{
    position: absolute;
    top:0.2rem;
    right: 0.2rem;
}
