
.maintenance-infos {
   
    .start-date {
        display: flex;

        .clock {
            margin-top: 3px;
        }
    }

    label{
        color: var(--color4-light);
        &:after{
            content: ':';
            padding: 0 3px;
            display: inline-block;
        }
    }

    .msg-info {
        margin-top: 5px;
        display: grid;
        color: var(--color-ko);
    }
}