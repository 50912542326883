@import "./../../commons/scss/common-dx-grid-and-treelist.scss";

.dx-treelist .dx-treelist-headers .dx-treelist-content .dx-treelist-table .dx-row.dx-header-row>td,
.dx-treelist .dx-row>td,
.dx-treelist .dx-pager {
    @include common-dxgrid-and-treelist-pager;
}

.dx-treelist .dx-treelist-headers .dx-treelist-content {
    @include common-dxgrid-and-treelist-headers;
}

.dx-treelist .dx-row-lines>td {
    @include common-dxgrid-and-treelist-dataloading;
}

.dx-treelist .dx-treelist-content .dx-treelist-table .dx-row>td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-editor-cell:not(.dx-command-select),
.dx-treelist .dx-treelist-content .dx-treelist-table .dx-row>td.dx-treelist-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-treelist-select-all).dx-editor-cell:not(.dx-command-select),
.dx-treelist .dx-treelist-content .dx-treelist-table .dx-row>td:not(.dx-validation-pending):not(.dx-treelist-select-all).dx-editor-cell:not(.dx-command-select) {
    @include common-dxgrid-and-treelist-border;
}

.dx-treelist .dx-treelist-content .dx-treelist-table {
    @include common-dxgrid-and-treelist-cellpading;
}

.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
    @include common-dxgrid-and-treelist-rowcolor;

    :before {
        color: var(--bs-white);
    }
}

.dx-treelist,
.dx-treelist-column-chooser-list {
    @include common-dxgrid-and-treelist-columnchooser-list;
}

.dx-treelist .dx-toolbar .dx-toolbar-items-container {
    @include common-dxgrid-and-treelist-toolbar;
}

.dx-treelist-column-chooser {
    @include common-dxgrid-and-treelist-columnchooser;
}

.dx-scrollable-scrollbar.dx-scrollbar-horizontal {
    @include common-dxgrid-and-treelist-scrollbar-horizontal;
}

// Added padding to prevent scroll bar going over the last row
.dx-treelist-rowsview .dx-scrollable-content {
    @include common-dxgrid-and-treelist-scrollablecontent;
}

div.dx-widget[role="presentation"][fixedscrollbar="true"] {
    @include common-dxgrid-and-treelist-fixedscrollbar;
}

.dx-treelist-content .render-boolean-check {
    @include common-dxgrid-and-treelist-booleancheck;
}

.dx-row.dx-treelist-filter-row {
    @include common-dxgrid-and-treelist-highlightfilter;
}

@include common-dxgrid-and-treelist-editor-with-menu;

.dx-treelist {
    @include common-dxgrid-and-treelist-rows-scroll;
}

.dxtreelist-column-render-boolean {
    @include common-dxgrid-and-treelist-rows-renderboolean;
}

.dx-treelist .dx-treelist-rowsview .dx-treelist-content .dx-treelist-table .dx-row:not(.dx-header-row) {
    @include common-dxgrid-and-treelist-rows-color-alternation;
}