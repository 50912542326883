@import "./../../../commons/scss/_common-mixins.scss";

.panel-language-chooser{

    background-color: transparent;

    ul{
        max-width: 3rem;
        width: 160px;
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        li{
            max-width: 3rem;
            width: 160px;
            padding: 0.1rem 0.2rem; 
        }
    }   
    .flag{
        width: 22px;
        height: 14px; 
        background-image: url('./flag_sprite.png');
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 0.3rem;

        &.flag-fr{ background-position: -351px -76px; }
        &.nl{ background-position: -378px -190px; }
        &.flag-en{ background-position: 0px -95px; }

    }

    .dropdown-menu.show{
        min-width: auto;
        li{
            width: 160px;
            margin: 0.25rem 0;
            .code{
                font-size: 0.75rem;
            }
        }
    }
    
    .dropdown{
        button[aria-expanded="false"] + ul{
            display: none;
        }

    }

    button:focus{
        box-shadow: none;
    }
}