.popover-params-content{

    label{
        padding-top: 7px;
    }

    &.row{
        padding: 0 10px;
    }
}
