@import "./_common-mixins.scss";

.modal-header {
    padding: 0.8rem;

    .modal-title {
        &.h4 {
            font-size: 1rem;
        }
    }

    // .btn-close {
    //     box-sizing: content-box;
    //     width: 1em;
    //     height: 1em;
    //     padding: .25em;
    //     color: #000;
    //     background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    //     border: 0;
    //     border-radius: .25rem;
    //     opacity: .5;
    // }
}

.modal-body {
    p {
        font-size: 0.9rem;
        text-align: center;

        &.primary {
            color: var(--color-primary-b);
        }
    }
}

.dx-popup-wrapper>.dx-overlay-content {

    @include border-radius(0.5rem);

    .dx-toolbar-label>div {
        font-size: 0.9rem;
        font-weight: 600;
    }

    .dx-button-mode-text {
        box-shadow: none;
        @include border-radius(0.5rem);

        &.dx-button-default {
            background-color: var(--color4-light);
            color: var(--color-button-text);

            .dx-icon {
                color: var(--color-button-text);
            }
        }

        &.dx-button-primary {
            background-color: var(--color-primary-b);
            border-color: var(--color-primary-b);
            color: var(--color-button-text);

            .dx-icon {
                color: var(--color-button-text);
            }
        }

        &.dx-button-secondary {
            background-color: var(--color4);
            border-color: var(--color4);
            color: var(--color-button-text);

            .dx-icon {
                color: var(--color-button-text);
            }
        }
    }

    .dx-button-text {
        text-transform: none;
        letter-spacing: inherit;
    }

    .dx-popup-bottom.dx-toolbar {
        border-top: solid 1px #ccc;

        .dx-toolbar-items-container {
            text-align: right;

            .dx-toolbar-center {
                display: inline-block;
                padding: 10px 0;
            }
        }
    }

    form .footer,
    div .footer {
        margin: 10px -15px -15px;
    }

    .modal-content + .footer{
        margin: 10px 0 0;
    }

    .footer {
        border-top: solid 1px var(--color2-border);
        text-align: right;
        padding: 10px;
        margin: 10px 0 0;
    }

    .dx-popup-content>div>.footer {
        margin: 10px -15px -15px;
    }
}

.dx-popup-normal {

    .dx-popup-footer {
        bottom: 0;
        right: 0;
        padding: 10px 0 0;
        margin: 10px 0 0;
        border-top: solid 1px #ccc;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}