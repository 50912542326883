@import "./../../commons/scss/common-dx-grid-and-treelist.scss";

.dx-datagrid .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row>td,
.dx-datagrid .dx-row>td,
.dx-datagrid .dx-pager {
    @include common-dxgrid-and-treelist-pager;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-content {
    @include common-dxgrid-and-treelist-headers;
}

.dx-datagrid .dx-row-lines>td {
    @include common-dxgrid-and-treelist-dataloading;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-editor-cell:not(.dx-command-select),
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-editor-cell:not(.dx-command-select),
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-editor-cell:not(.dx-command-select) {
    @include common-dxgrid-and-treelist-border;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table {
    @include common-dxgrid-and-treelist-cellpading;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
    @include common-dxgrid-and-treelist-rowcolor;
}

.dx-datagrid,
.dx-datagrid-column-chooser-list {
    @include common-dxgrid-and-treelist-columnchooser-list;
}

.dx-datagrid .dx-toolbar .dx-toolbar-items-container {
    @include common-dxgrid-and-treelist-toolbar;
}

.dx-datagrid-column-chooser {
    @include common-dxgrid-and-treelist-columnchooser;
}

.dx-scrollable-scrollbar.dx-scrollbar-horizontal {
    @include common-dxgrid-and-treelist-scrollbar-horizontal;
}

// Added padding to prevent scroll bar going over the last row
.dx-datagrid-rowsview .dx-scrollable-content {
    @include common-dxgrid-and-treelist-scrollablecontent;
}


div.dx-widget[role="presentation"][fixedscrollbar="true"] {
    @include common-dxgrid-and-treelist-fixedscrollbar;
}

.dx-datagrid-content .render-boolean-check {
    @include common-dxgrid-and-treelist-booleancheck;
}


.dx-row.dx-datagrid-filter-row {
    @include common-dxgrid-and-treelist-highlightfilter;

    .dx-texteditor-input-container .dx-placeholder {
        top: -5px;
    }
}



@include common-dxgrid-and-treelist-editor-with-menu;


.dx-datagrid {
    @include common-dxgrid-and-treelist-rows-scroll;
    
    .dx-editor-with-menu .dx-texteditor .dx-placeholder::before,
    .dx-editor-with-menu .dx-texteditor .dx-texteditor-input{
        padding-left: 24px;
    }
}

// Let the horizontal scroll bar always visible
.analysis-dashboard {
    .dx-scrollbar-horizontal {
        margin-bottom: 0px !important;
    }
}


.dxgrid-column-render-boolean {
    @include common-dxgrid-and-treelist-rows-renderboolean;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table .dx-row:not(.dx-header-row) {
    @include common-dxgrid-and-treelist-rows-color-alternation;
}

.dx-scrollable-scroll-content {
    background-color: var(--color3);
}

.dx-datagrid-table.dx-datagrid-table-fixed tbody[role=presentation] .dx-row.dx-datagrid-filter-row {
    td[role=gridcell]{
        height: 25px;
    }
}
