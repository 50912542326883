@mixin common-dxgrid-and-treelist-pager {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 8px;

    .dx-page-sizes {
        .dx-texteditor.dx-editor-filled {
            background-color: var(--color1);

            &:after {
                border-bottom: none;
            }
        }

        .dx-texteditor.dx-state-focused {
            &:before {
                border-color: var(--color6);
            }
        }
    }

    .dx-texteditor.dx-editor-filled {
        &:after {
            border-bottom: none;
        }
    }

    .dx-datagrid-pager,
    .dx-pages {

        margin-top: 0;

        .dx-texteditor.dx-editor-filled {
            .dx-texteditor-input {
                font-family: var(--common-font);
                color: var(--color6);
                background-color: var(--color1);
                min-width: 34px;
                text-align: center;
                padding: 9px 0px 8px;
            }

            &:after {
                border-bottom: none;
            }
        }
    }

    .dx-texteditor.dx-state-focused {
        &:before {
            border-color: var(--color6);
        }
    }

    .dx-pages .dx-prev-button::before {
        margin-left: 5px;
    }

    .dx-light-pages .dx-info-text {
        padding-left: 2px;
    }
}

@mixin common-dxgrid-and-treelist-headers {
    margin-top: -1px;

    .dx-datagrid-table .dx-row.dx-header-row,
    .dx-treelist-table .dx-row.dx-header-row {

        &>td,
        &>td.dx-command-edit {
            background-color: var(--color6lighten3);
            border-color: var(--color2);
            position: relative;
        }
    }
}

@mixin common-dxgrid-and-treelist-dataloading {
    border: 1px solid var(--color2);

    .data-error {
        font-style: italic;
        color: var(--color-ko);
        font-size: 11px;
        opacity: 0.7;
    }

    .data-loading {
        font-style: italic;
        color: var(--color6);
    }
}

@mixin common-dxgrid-and-treelist-border {
    border: solid 1px var(--color2);
}

@mixin common-dxgrid-and-treelist-cellpading {

    .dx-row>td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-command-select,
    .dx-row>td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-select,
    .dx-row>td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-select,

    .dx-row>td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-command-select,
    .dx-row>td.dx-treelist-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-treelist-select-all).dx-command-select,
    .dx-row>td:not(.dx-validation-pending):not(.dx-treelist-select-all).dx-command-select {
        padding-left: 2px;
    }
}

@mixin common-dxgrid-and-treelist-rowcolor {
    background-color: var(--color6);
    color: var(--color1);
}

@mixin common-dxgrid-and-treelist-columnchooser-list {

    .dx-pager .dx-page-sizes .dx-selection,
    .dx-pager .dx-pages .dx-selection,
    .dx-checkbox-indeterminate .dx-checkbox-icon,
    .dx-checkbox-checked .dx-checkbox-icon {
        background-color: var(--color6lighten);
    }

    .dx-treeview-node .dx-checkbox {
        top: 0px;
    }

    .dx-treeview-item {
        min-height: unset;
        padding: 0px 8px 0px 32px;
        margin-bottom: 4px;
    }

    .dx-treeview-toggle-item-visibility {
        height: 13px;
    }
}

@mixin common-dxgrid-and-treelist-toolbar {
    height: 30px;
}

@mixin common-dxgrid-and-treelist-columnchooser {
    .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
        height: 30px;
    }
}

@mixin common-dxgrid-and-treelist-scrollbar-horizontal {
    height: 13px !important;
    margin-bottom: -11px;

    .dx-scrollable-scroll {
        height: 13px !important;

        .dx-scrollable-scroll-content {
            height: 13px !important;
        }
    }
}

@mixin common-dxgrid-and-treelist-scrollablecontent {
    padding-bottom: 0;
}

@mixin common-dxgrid-and-treelist-fixedscrollbar {
    .dx-scrollable-scrollbar.dx-scrollbar-horizontal {
        position: fixed;
        left: auto;
    }

    .dx-datagrid-headers,
    .dx-treelist-headers {
        position: sticky;
        z-index: 4;
        top: 0px;
    }
}

@mixin common-dxgrid-and-treelist-booleancheck {
    color: var(--color-ok);
}

/* Highlights the selected filters in the grid */
@mixin common-dxgrid-and-treelist-highlightfilter() {
    .dx-show-invalid-badge.dx-selectbox.dx-textbox.dx-texteditor {
        &:has(input[value]) {
            border-bottom: solid 3px var(--color-primary-b);
        }

        &:has(input[value=""]) {
            border-bottom: solid 2px transparent;
        }
    }
}

@mixin filter-active-marker($cornerColor, $HPosTop : 0px, $HPosLeft : 5px) {
    background-image: linear-gradient(135deg, $cornerColor 0%, $cornerColor 5%, rgba(171, 171, 171, 0) 5%, rgba(171, 171, 171, 0) 100%);
    background-size: 210px 210px;
}

@mixin common-dxgrid-and-treelist-editor-with-menu {
    .dx-editor-with-menu {

        .dx-editor-container .dx-texteditor-container {

            .dx-texteditor-input-container {

                flex-direction: row-reverse;

                .dx-state-invisible {

                    width: 16px;
                    height: 16px;
                    display: block !important;
                    position: relative;
                    margin: 0;
                    padding: 0;
                    color: var(--color3);

                    &:before {
                        animation: blinkMarker 5s linear infinite;

                        content: '';
                        display: block !important;
                        width: 0;
                        height: 0;
                        position: absolute;
                        top: 5px;
                        left: 1px;
                        padding: 0;
                        margin: 0;
                        border-style: solid;
                        border-width: 4px 0 4px 6px;
                        border-color: transparent transparent transparent var(--color-click);
                        opacity: 1;

                    }

                    @keyframes blinkMarker {
                        90% {
                            opacity: 1;
                        }

                        95% {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}

@mixin common-dxgrid-and-treelist-rows-scroll {
    .dx-scrollbar-vertical.dx-scrollbar-hoverable {
        width: 11px;
    }

    .dx-scrollbar-vertical .dx-scrollable-scroll {
        width: 11px;
    }

    $otherColColorHeader : #b4d0e1;
    $otherColColorEven : #cccccc;
    $otherColColorOdd : #e7e7e7;

    .dx-header-row {
        .header-center {
            text-align: center !important
        }


        .bloc-1[role=columnheader],
        .bloc-3[role=columnheader] {
            background-color: $otherColColorHeader !important;
        }

        .dx-pager {
            margin-top: 15px !important;
        }
    }

    .dx-row:not(.dx-selection) {
        &[aria-rowindex]:nth-child(odd) {

            .bloc-1,
            .bloc-3 {
                background-color: $otherColColorOdd !important;
            }
        }

        &[aria-rowindex]:nth-child(even) {

            .bloc-1,
            .bloc-3 {
                background-color: $otherColColorEven !important;
            }
        }
    }

    .dx-editor-cell .dx-texteditor .dx-texteditor-input {

        height: 22px;
        line-height: 22px;

    }
}

@mixin common-dxgrid-and-treelist-rows-renderboolean {
    text-align: center;

    .fa-check {
        color: var(--color6);
    }

    .dx-selection & {
        .fa-check {
            color: var(--color1);
        }
    }
}

@mixin common-dxgrid-and-treelist-rows-color-alternation {
    &:nth-child(even) {
        background-color: #e5e5e5
    }

    &:nth-child(odd) {
        background-color: #f3f3f3
    }
}