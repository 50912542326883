.log-panel-infos {

    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    min-height: 12px;
    z-index: 9000;

    .button-open {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.6rem;
        line-height: 10px;
        min-width: 17px;
        background-color: var(--color1);

        &.empty {
            color: var(--color7) //color: var(--color1-dark)
        }

        cursor:pointer;
        white-space: nowrap;
        padding: 1px;

        &:hover {
            font-size: 0.65rem;
        }
    }

    .log-panel-infos-content {

        border: solid 1px #ccc;
        background-color: #ddd;
        max-height: 400px;
        min-width: 500px;
        overflow: auto;
        padding: 0.5rem;

        >.header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .eraser {
                padding: 0.5rem 0 0 2rem;
                margin: 0;
    
                >span {
                    margin-left: 1rem;
    
                    :hover {
                        color: var(--color-ok)
                    }
                }
    
               
            }
    
            .version{
                .version-number{
                    font-size: 11px;
                    color: var(--color4-light);
                }
            }
    
            .close {
                text-align: right;
                font-size: 1.2rem;
                margin: 0;
                padding: 0.5rem 0.5rem 0 0;
    
                >span {
                    color: var(--color4-light);
                    cursor: pointer;
    
                    &:hover {
                        color: var(--color4)
                    }
                }
            }
    
        }

        
        .log-item {
            font-size: 0.75rem;

            em {
                color: var(--color4);
            }

            &.error {
                strong {
                    color: var(--color-ko);
                }
            }

            .log-item-header {

                .last-log {
                    background-color: var(--color3lighten2Opacity);

                    &.warm {
                        background-color: var(--color3lighten);
                    }

                    &.hot {
                        background-color: var(--color3);
                    }
                }

                border-bottom: dashed 1px var(--color8);
                position: relative;
                padding-right: 15px;

                .code {
                    display: inline-block;

                    margin: 0 3px;
                }

                .status {
                    display: inline-block;
                    font-weight: 600;
                    background-color: var(--color-ko);
                    color: var(--color1);
                    padding: 1px 3px 0;
                    margin: 0 3px;
                }

                .url {
                    display: block;
                }

                .toggle {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    font-size: 14px;
                    color: var(--color4-light);

                    &:hover {
                        color: var(--color4);
                    }
                }
            }

            .log-item-msg-detail {

                h1,
                h2,
                h3 {
                    font-size: 10px;
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                }

                hr {
                    margin: 0;
                }

                p {
                    margin: 0;
                }

                font-size: 10px;
                font-weight: 600;
                color: var(--color-ko);

            }
        }

        ul {
            padding-left: 0.8rem;
            max-width: 500px;

            li {
                padding: 0.5rem;
            }
        }
    }
}