.popup-user-content {

    .name-content {

        margin: 0 5px 0 0;

        &.email {
            display: block;
        }
    }

    .my-settings {
        color: var(--color4-light);
        cursor: pointer;
        margin: 5px 0 5px 0;

        &:hover {
            color: var(--color3);
        }

        &::before {
            content: '> ';
            padding: '5px';
            font-weight: 600;
            color: var(--color3);
        }
    }

    .buttons {
        margin: 10px -15px 0;
        padding: 10px 10px 0;
        border-top: solid 1px var(--color2-light);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .reset-button-with-info{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .fa-info-circle{
                color: var(--color-default-b);
            }
        }
    }

}