.clock{
    font-size: 10px;
    padding: 0 5px;

    .clock-mode{
        padding: 1px 2px;
        margin: 0 1px;
        color: var(--color3);
        font-weight: 600;
        line-height: 9px;
        background-color: #fffa;
        display: inline-block;
        border-radius: 0.5rem;
    }

    .clock-time{
        font-weight: 600;
    }
}

.clock-tooltip{
    & > span{
        display: block;
        text-align: right;
    }
}