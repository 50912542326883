@import "~bootstrap/scss/bootstrap";

// react-date-range
@import "react-date-range/dist/styles.css"; // main style file
@import "react-date-range/dist/theme/default.css"; // theme css file

@import "bootstrap-daterangepicker/daterangepicker.css";



//@import 'devextreme/dist/css/dx.light.css';
@import 'devexpress-gantt/dist/dx-gantt.min.css';


:root {
	--color1: #fff;
	--color1Opacity: rgba(255, 255, 255, 0.5);
	--color1-dark: #eee;
	--color2: #9eaab6;
	--color2-light: #b8c2cc;
	--color2-verylight: #e7e3e3;
	--color2-ultralight: #f1efef;
	--color2-border: #e0e0e0;
	--color3: #ff9633;
	--color3bis: #ffb443;
	--color3Opacity: rgba(255, 150, 51, 0.5);
	--color3lighten: #ffda75;
	--color3lighten2: #f6e3ad;
	--color3lighten2Opacity: #f6e3ad55;
	--color4: #333;
	--color4-light: #777;
	--color5: #000;
	--color5Opacity: rgba(0, 0, 0, 0.5);
	--color6: #296e99;
	--color6light: #5b9ec7;
	--color6lighten: #64abd6;
	--color6lighten2: #86bee0;
	--color6lighten2Opacity: #86bde070;
	--color6lighten3: #d8e7f0;
	--color7: #03468d;
	--color8: #0f284c;
	--color9: #0a1c36;
	--color10: #fff133;
	--color11: #1f5f29;
	--color-ok: #55cc11;
	--color-ko: #dd3300;
	--color-click: #f50;
	--color-transparent: rgba(0, 0, 0, 0);

	--color-background-panel: var(--color1);
	--color-background-panel-info: var(--color2-light);
	--color-background-panel-elements: var(--color2);
	--color-titre: var(--color1);
	--color-text: var(--color5);
	--color-text-panel: var(--color4);
	--color-text-panel-info: var(--color4);
	--color-text-hover: var(--color3);
	--color-navigation: var(--color6);
	--color-navigation-secondaire: var(--color2);
	--color-button-text: var(--color1);
	--color-primary-b: var(--color-click);
	--color-secondary-b: var(--color6);
	--color-default-b: var(--color4-light);
	--color-light-f: var(--color4);
	--color-light-b: var(--color2);
	--color-background1: var(--color8);
	--color-background1-shadow: var(--color9);
	--color-background2: var(--color7);
	--common-font: Tahoma, Geneva, sans-serif;

	--color-scenario-type-ACT: var(--color11);
	--color-scenario-type-OTB: #0062cc;
	--color-scenario-type-FCT: #ff2238;
	--color-scenario-type-BUD: #ff9c07;

	--color-file-PDF: #cc0a1e;
	--color-file-XLS: #04770a;

	--colorSectionParentDirect: var(--color-ko);
	--colorSectionParent: #ff550033;

	--colorSectionCurrent: var(--color3);

	--colorSectionChildrenDirect: var(--color6);
	--colorSectionChildren: #296e9955;

	--color_INITIATOR: #89bb00;
	--color_bg_INITIATOR: #89bb0066;

	--color_REVIEWER: #009ccc;
	--color_bg_REVIEWER: #009ccc66;

	--color_APPROVER: #cc4100;
	--color_bg_APPROVER: #cc410066;

	--color_DISAPPROVE: #6e2300;
	--color_bg_DISAPPROVE: #6e230066;

	--color_SKIPPED: #ccaa00;
	--color_bg_SKIPPED: #ccaa0066;

	--color_FINALIZED: var(--color7);
	--color_bg_FINALIZED: var(--color6lighten2);
}

#root {
	&>.ui.container {
		width: auto;
		padding: 0;
	}

	font-family: var(--common-font);
	scrollbar-color: var(--color3) var(--color1);

}

.no-padding {
	padding: 0px !important;
}

// aligné sur les labels des inputs texts
.form-group label.form-label {
	font-size: 0.75rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-right: 0.2rem;
	padding-bottom: 0.3rem;
	padding-left: 0.8rem;
}

small.text-muted {
	padding-left: 0.8rem;
	margin-top: 0.05rem;
	font-size: 0.75rem;
	display: block;

	&.input-error {
		color: var(--color-ko) !important;
	}
}

h3 {
	color: var(--color6lighten);
}

.custom-control-label {
	font-family: var(--common-font);
	font-size: 0.9rem;
	padding-top: 0.25rem;
	padding-left: 0.25rem;
}

.input-text.common-radio-group {
	padding: 0.4rem 0;
}

.dx-widget {
	font-family: var(--common-font);

	&.dx-radiogroup-horizontal {
		.dx-item.dx-radiobutton {
			background-color: var(--color6lighten3);
			border: solid 2px var(--color6lighten3);
			padding: 0.35rem 0.75rem 0.25rem 0.5rem;
			@include border-radius(0.8rem);
			color: var(--color6);
			display: inline-flex;

			&.dx-item-selected {
				color: var(--color5);
			}
		}
	}

	&.dx-state-disabled.dx-texteditor {
		opacity: 0.85;
	}
}

.custom-control {
	input.ccbl-custom-control-input[type="checkbox"] {
		&+.custom-control-label {
			cursor: pointer;
		}
	}

	input[type="radio"],
	input[type="checkbox"] {
		box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
		padding: 0; // 2. Remove the padding in IE 10-
	}
}

.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	border-color: var(--color6);
	background-color: var(--color6);
	width: 18px;
	height: 18px;
}

.custom-checkbox .custom-control-label::before {
	border-color: var(--color6);
	width: 18px;
	height: 18px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
	width: 18px;
	height: 18px;
}

.form-group label.form-label,
.input-text.light .form-control {
	font-size: 0.85rem;
}

body .form-select:focus {
	border-color: transparent;
	outline: 0;
	box-shadow: none;
}

.input-text+small.form-field-error-message {
	display: block;
	padding: 0 0 0 10px;
	color: var(--color-ko);
	font-size: 0.7rem;
}

.form-label,
.input-text .form-label,
.input-with-label .form-label,
.common-selector-label.form-label,
.input-text .input-text-form-label {
	font-size: 0.75rem;
	margin: 0;
	padding-left: 0.8rem;
}

button.btn {
	margin: 2px;
}

.spa .full.container {
	max-width: inherit;
}


span.badge.bg-primary {
	color: var(--color1) !important;
	background-color: var(--color6) !important;
}

.dx-selection span.badge.bg-primary {
	color: var(--color6) !important;
	background-color: var(--color1) !important;
}

.dx-button .dx-button-content {
	padding: 5px;
}


.dx-calendar-cell.dx-calendar-selected-date span,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today span {
	background-color: var(--color6);
}

.dx-calendar-navigator .dx-button {
	color: var(--color7);
}


/*
	to avoid iframe overlaying body when HMR is triggered
*/

body>iframe {
	background-color: #ff000044;
	margin-top: 99vh;
}


// With updates of packages, new css rules have been added to devextreme components
// This is a fix to avoid unwanted rules

.dx-calendar-cell {
	span {
		height: inherit !important;
		width: inherit !important;
	}

	&.dx-calendar-today {
		span {
			border: none !important;
		}
	}
}