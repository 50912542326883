@import "~bootstrap/scss/bootstrap";
@import "../../predict/predicTemplate/template/flog/nodes/node-mixins";

.top-nav {

    // hack bootstrap skaffolding
    // pour diminuer la place du logo et des boutons dans le topnav
    // en mode xl
    @include media-breakpoint-only(xl) {
        &>.row.no-gutters {
            &>.col-xl-2 {
                flex-basis: 12%;
                max-width: 12%;
            }

            &>.col-xl-8 {
                flex-basis: 76%;
                max-width: 76%;
            }
        }
    }

    background-color: var(--color6lighten);
    color: #fff;
    width: 100%;

    .button-refresh-analysis-grid {
        width: 95%;
        display: inline-flex;

        &>div:not(.spinner-infinity) {
            width: 100%;
        }

        .btn {
            width: 100%;
            background-color: var(--color1);
            border-color: var(--color1);
            color: var(--color9);
            padding: 0.18rem 0.3rem 0.15rem 0.4rem;
            margin: 0 1.0rem;
            line-height: 1.5rem;

            &>.svg-inline--fa,
            & .spinner-infinity {
                color: var(--color9);
                font-size: 1.1rem;
                vertical-align: middle;
                margin-right: 0.5rem;
            }

            & .spinner-infinity {

                margin-top: 0.1rem;
            }

           
        }

    }

    &>.row>div>h1 {
        font-size: 19px;
        padding: 10px 0 0 0;
    }

    .children-container {
        display: flex;
        align-items: center;
    }

   
}

.top-nav .children-container .button-back-panel-landing {
    padding: 0.2rem 0.5rem;

    &+h1 {
        // border: solid 1px #fc0;
        line-height: 20px;
        padding: 0 0 0 20px;
    }
}

