.formatted-number-cell {
    &.red-neg-cell {
        color: red;
    }
}

.dx-selection{
    .formatted-number-cell {
        &.red-neg-cell {
            color: var(--color1);
        }
    }
}