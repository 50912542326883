@import "./../../../commons/scss/_common-mixins.scss";


.custom-toggle-icon{

    .topnav-analysis-dashboard & {
        color: var(--color1);
    } 

    // color: var(--color2);
    // padding: 0.4rem 0.2rem;
    // display: inline-block;
    // line-height: 16px;

    @include border-radius(30px);
        display: inline-block;
        height: 24px;
        width: 24px;
  
        font-size: 14px;
        padding: 0 7px;

        &:hover{
            @include box-shadow(0rem, 0rem, 0.1rem, 0.25rem, var(--color6lighten2));
        }


}

.no-toggle .inhovate-custom-toggle .custom-toggle-icon.custom-toggle-icon-with-tooltip{

    color: var(--color1);
    background-color: var(--color6);
    display: block;

    > svg{
        height: 24px;
        width: 14px;
        margin: 0 1px;
    }
   
}