.field-dropdown-selector-wrapper{

    .dropdown-selector.dropdown-property.dd-selector-wrapper{
        &.light{
            
            .inhovate-custom-toggle{
                background-color: transparent;
                color: var(--color6);
                padding-right: 15px;
            }
        }

        /* TOGGLE */
        .dd-selector-wrapper-toggle{
            .dd-selector-wrapper-item{
                border: solid 1px var(--color6);
            }
        }

        
        .custom-toggle-icon.standard {
            position: absolute;
            top: 0.2rem;
            right: -0.3rem;
            &:hover{
                box-shadow: none;
                svg{
                    color: var(--color7);
                }
                
            }
        }

        &.full-value-loading{
            .custom-toggle-icon.standard {
                display: none;
            }
        }
    }

 
}

