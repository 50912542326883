@import "~bootstrap/scss/bootstrap";
@import "../../predict/predicTemplate/template/flog/nodes/node-mixins";

.top-nav-buttons{
    text-align: right;
    margin-top: 0.4rem;
    margin-right: 0.8rem;
    
    .dropdown-toggle::after {
        border-top-color: #fff;
    }

    .btn.btn-default{
        &:focus,&:hover{box-shadow: none;background-color: transparent;}
        font-size: 1.0rem;
        width: auto;
        margin-left: 1.2rem;
        padding: 0;
        border: 0;
        background-color: transparent;
        svg{color: #fff;}
        .user-logged{
            color: #fff;
            padding-left: 0.4rem;
            display: inline-block;
        }

        &.alert-msg-not-read{
            svg{
                color : var(--color-ko);
                background-color: #fffa;
                padding: 2px;
                @include border-radius(5px);
                width: 17px;
                height: 19px;
                margin-top: 1px;
                @include blink();
            }
        }
    }
}

.top-menu-popover-options{
    h3{
        text-align: center;
    }
}

.top-menu-popover-user {
    width: 290px;
}

.content-common-overlay .field-company-selector .common-dropdown-grid-selector {
    width:250px;
}


